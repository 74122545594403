import React from 'react';
import './topbar.css';


const Topbar = (props) => {
    const { onhandleChange, topbarClass } = props;

    return (
        <>
            <header className={[topbarClass == false ? "mainHeader" : "mainHeader mainHeaderSlide"]}>
                <button className={[topbarClass == false ? "slideBtn" : "slideBtn slideBtnOpen"]} onClick={() => onhandleChange()}>
                    <span className="transition300"></span>
                    <span className="transition300"></span>
                    <span className="transition300"></span>
                </button>
            </header>
        </>
    )
}

export default Topbar