import logo from './logo.svg';
import './App.css';
import './assets/css/fontawesome.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Login, Dashboard } from './pages';
// import routes from "./routes";
import RoutesPage from './RoutesPage';

function App() {
  return (
    <>
      <RoutesPage />
      {/* <Router>
        <Routes>
          <Route path={'/'} element={<Login />} />
          <Route path={'/dashboard'} element={<Dashboard />} />
        </Routes>
      </Router> */}
    </>
  );
}

export default App;
