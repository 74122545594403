import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Sidebar, Topbar } from "./components";
import { Login, Dashboard, VehicleList } from './pages';

const RoutesPage = () => {
    const [topbarClass, setTopbarClass] = useState(false);
    const onTopBarFunc = () => {
        setTopbarClass(!topbarClass);
    }


    return (
        <>

            <Router>
                <div className="dashboardBody">

                    <Sidebar topbarClass={topbarClass} />
                    <Topbar topbarClass={topbarClass} onhandleChange={onTopBarFunc} />

                    <div className={[topbarClass == false ? "dashboardRight" : "dashboardRight dashboardRightClose"]}>
                        <Routes>
                            <Route path={'/'} element={<Login />} />
                            <Route path={'/dashboard'} element={<Dashboard topbarClass={topbarClass} />} />
                            <Route path={'/log-dashboard'} element={<VehicleList />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </>
    )

}
export default RoutesPage;