import React, { useState } from 'react';
import './dashboard.css';
import VehicleList from '../VehicleList/VehicleList';

const Dashboard = (props) => {
    const { topbarClass } = props;
    return (
        <>

            <VehicleList />

        </>
    )
}

export default Dashboard