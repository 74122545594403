import React from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';

import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className="login">
                <header className="loginHeader">
                    <div className="logo">
                        <img src={require('../../assets/img/logo.png')} />
                    </div>
                </header>
                <div className="login_box">
                    <div className="loginTop">
                        <h2>Welcome Back !</h2>
                        <p>Sign in to continue to Logistic</p>
                    </div>

                    <div className="loginBottom">
                        <form action="">
                            <div className="loginField">
                                <TextField fullWidth className='inputField' type="text" label="Enter Username" variant="filled" />
                            </div>
                            <div className="loginField">
                                <TextField fullWidth className='inputField' type="password" label="Enter Password" variant="filled" />
                            </div>
                            <div className="loginforget">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <FormControlLabel className='rememberCheckWrap' control={<Checkbox className='rememberCheck' />} label="Remember me" />
                                    </div>
                                    <div className="col-auto">
                                        <Link className='forgotpass' to={'/'}>Forgot Password ?</Link>
                                    </div>
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    navigate('/dashboard')
                                }}
                                fullWidth className='loginBtn' variant="contained">Login</Button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login