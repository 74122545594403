import React from 'react';
import './vehiclelist.css';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

const VehicleList = () => {
    const columns = [
        { field: 'registrationNo', headerName: 'Registration No', width: 160, },
        { field: 'vendorName', headerName: 'Vendor Name', width: 270, },
        { field: 'vehicleType', headerName: 'Vehicle Type', width: 120, },
        { field: 'make', headerName: 'Make', width: 120 },
        { field: 'age', headerName: 'Hours of Operation', width: 180, type: 'number', align: 'center' },
        { field: 'entryDate', headerName: 'Entry Date', width: 140 },
        { field: 'km', headerName: 'Opening hour/km meter', width: 200, align: 'center' },
        {
            headerName: 'Action',
            field: 'actions',
            type: 'actions',
            width: 150,
            renderCell: () => (
                <>
                    <div className="tableBtn">
                        <Button className='actionBtn viewBtn'><i className="far fa-eye"></i></Button>
                        <Button className='actionBtn editBtn'><i className="far fa-pen"></i></Button>
                        <Button className='actionBtn deleteBtn'><i className="far fa-trash-alt"></i></Button>
                        <Button className='actionBtn exitBtn'><img src={require('../../assets/img/exit_icon.png')} alt="" /></Button>
                    </div>
                </>
            ),
        },
    ];

    const rows = [
        {
            id: 1,
            registrationNo: 'Super8',
            vendorName: 'MAA AMBA TECHNO WORKS P.LTD',
            vehicleType: 'Poclain',
            make: 'JCB',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 2,
            registrationNo: 'WB15C5110',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 3,
            registrationNo: 'LOADER3-6398',
            vendorName: 'SRI SAI ASSOCIATES',
            vehicleType: 'Poclain',
            make: 'JCB',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 4,
            registrationNo: 'SUPER-18',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 5,
            registrationNo: 'SUPER-18',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 6,
            registrationNo: 'Super8',
            vendorName: 'MAA AMBA TECHNO WORKS P.LTD',
            vehicleType: 'Poclain',
            make: 'JCB',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 7,
            registrationNo: 'WB15C5110',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 8,
            registrationNo: 'LOADER3-6398',
            vendorName: 'SRI SAI ASSOCIATES',
            vehicleType: 'Poclain',
            make: 'JCB',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 9,
            registrationNo: 'SUPER-18',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },
        {
            id: 10,
            registrationNo: 'SUPER-18',
            vendorName: 'BIPAD BARAN MONDAL',
            vehicleType: 'Hyva',
            make: 'TATA',
            age: 25,
            entryDate: '4 Jan 2023',
            km: 54000,
        },

    ];

    const columns2 = [
        { field: 'vendorName', headerName: 'Vendor Name', width: 250, },
        { field: 'vendorCode', headerName: 'Vendor Code', align: 'center', width: 120, },
        { field: 'vendorNickName', headerName: 'Vendor Nick Name', width: 150, align: 'center' },
        { field: 'address', headerName: 'Address', width: 250, align: 'left' },
        { field: 'email', headerName: 'Email ID', width: 180 },
        { field: 'contactNo', headerName: 'Contact No', width: 180, align: 'left' },
        {
            headerName: 'Action',
            field: 'actions',
            type: 'actions',
            width: 120,
            renderCell: () => (
                <>
                    <div className="tableBtn">
                        <Button className='actionBtn viewBtn'><i className="far fa-eye"></i></Button>
                        <Button className='actionBtn editBtn'><i className="far fa-pen"></i></Button>
                        <Button className='actionBtn deleteBtn'><i className="far fa-trash-alt"></i></Button>
                    </div>
                </>
            ),
        },
    ];

    const rows2 = [
        {
            id: 1,
            vendorName: 'ASHOK TRANSPORTING',
            vendorCode: '001',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 2,
            vendorName: 'BIPAD BARAN MONDAL',
            vendorCode: '002',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 3,
            vendorName: 'MAA AMBA TECHNO WORKS P.LTD',
            vendorCode: '003',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 4,
            vendorName: 'MAA TARA TRADING',
            vendorCode: '004',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 5,
            vendorName: 'ASHOK TRANSPORTING',
            vendorCode: '005',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 6,
            vendorName: 'MAA TARA TRADING',
            vendorCode: '006',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },
        {
            id: 7,
            vendorName: 'SRI SAI ASSOCIATES',
            vendorCode: '007',
            vendorNickName: 'Ashok',
            address: '15 Bhiringi More, Durgapur, 715123',
            email: 'abc@gmail.com',
            contactNo: '+91 98321 98321',
        },

    ];

    return (
        <>
            <h1>Logistic Dashboard</h1>
            <div className="dashboardInner">
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <div className="dashboard_box align-items-center">
                            <div className="dashboard_box_img">
                                <i className="fal fa-car-garage"></i>
                            </div>
                            <div className="dashboard_box_item">
                                <h3>Total Vendor</h3>
                                <span>50</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="dashboard_box align-items-center">
                            <div className="dashboard_box_img">
                                <i className="fal fa-car-side"></i>
                            </div>
                            <div className="dashboard_box_item">
                                <h3>Total Vehicle</h3>
                                <span>250</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="dashboard_box align-items-center">
                            <div className="dashboard_box_img">
                                <i className="fal fa-car-garage"></i>
                            </div>
                            <div className="dashboard_box_item">
                                <h3>Active Vehicle</h3>
                                <span>170</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <div className="search_vehicle">
                    <h4>Search Vehicle</h4>
                    <Grid container spacing={3} className="tableSearchBar">
                        <Grid item xs={8}>
                            <div className="tableSearchForm row">
                                <div className="tableSearchForm_select">
                                    <select className='selectBox' name="" id="">
                                        <option value="">Search Vehicle by</option>
                                    </select>
                                </div>
                                <div className="tableSearchForm_input col">
                                    <input className='inputBox' type='text' placeholder='Please enter search parameter here' />
                                </div>
                                <div className="tableSearchForm_button col-auto">
                                    <Button className='orangeBtn' variant="contained">Search Vehicle</Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="tableWithHeader">
                        <div className="tableCustomHeader align-items-center row justify-content-between">
                            <div className="col-auto">
                                <div className="vehicleList_heading">
                                    <i className="fal fa-car"></i>
                                    Vehicle List
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="addBtnwrap">
                                    <span>View All vehicle</span>
                                    <Button variant="contained" className='orangeBtn'>Add new vehicle</Button>
                                </div>
                            </div>
                        </div>


                        <div className="protextHideWrap">
                            <div style={{ height: 380, width: '100%' }}>
                                <DataGridPro
                                    className='protextHide tableActionBtn'
                                    rows={rows}
                                    columns={columns}
                                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                                />
                            </div>
                        </div>
                    </div>

                </div>


                <div className="search_vehicle">
                    <h4>Search Vendor</h4>
                    <Grid container spacing={3} className="tableSearchBar">
                        <Grid item xs={8}>
                            <div className="tableSearchForm row">
                                <div className="tableSearchForm_select">
                                    <select className='selectBox' name="" id="">
                                        <option value="">Search Vendor by</option>
                                    </select>
                                </div>
                                <div className="tableSearchForm_input col">
                                    <input className='inputBox' type='text' placeholder='Please enter search parameter here' />
                                </div>
                                <div className="tableSearchForm_button col-auto">
                                    <Button className='orangeBtn' variant="contained">Search Vendor</Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="tableWithHeader">
                        <div className="tableCustomHeader align-items-center row justify-content-between">
                            <div className="col-auto">
                                <div className="vehicleList_heading">
                                    <i className="fal fa-car-garage"></i>
                                    Vendor List
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="addBtnwrap">
                                    <span>View All vendor</span>
                                    <Button variant="contained" className='orangeBtn'>Add new vendor</Button>
                                </div>
                            </div>
                        </div>


                        <div className="protextHideWrap">
                            <div style={{ height: 380, width: '100%' }}>
                                <DataGridPro
                                    className='protextHide tableActionBtn'
                                    rows={rows2}
                                    columns={columns2}
                                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}

export default VehicleList